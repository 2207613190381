import { loadStripe } from '@stripe/stripe-js';

//const stripePromise = loadStripe('pk_test_51OHeInIwWdElnixOD9XtjInjt3Tk309gCAtyYva2VOdoNx9FrGORpc4vyszxKDD5DlWQywySPQ3PGYtZfPPhq78Z00Slnp2HBi');
const stripePromise = loadStripe('pk_live_51OHeInIwWdElnixOqPqSDC5ZffFod2gEIw9Hr5nqECp3EnhX9oW8A1Gm2zZik7zVBNMxpZY5OQ2PoYPNaOgYkakz00uiauzNic');
export const initiatePayment = async (resultId) => {
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: 'price_1PiGlCIwWdElnixO9CHTkkuM',
          quantity: 1,
        },
      ],
      mode: 'payment',
      successUrl: `${window.location.origin}/full-results/${resultId}`,
      cancelUrl: `${window.location.origin}/results`,
    });
  
    if (error) {
      console.error('Error:', error);
      throw new Error(error.message);
    }
  };