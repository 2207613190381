import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmail, signUpWithEmail } from '../services/firebase';
import { Button, TextField, Typography, Box, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import logo from '../images/logo.svg';
import Footer from './Footer';

const PageWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
});

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: 1,
  padding: theme.spacing(2.5, 2.5),
}));

const Logo = styled('img')({
  width: '150px',
  height: 'auto',
  marginTop: '20px',
});

const StyledPaper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '450px',
  width: '100%',
  border: '1px solid #BDA7A0',
  borderRadius: '20px',
}));

const ContentWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: 1,
  width: '100%',
});

const StyledFooter = styled(Box)({
  width: '100%',
});

const ToggleText = styled(Typography)({
  textDecoration: 'underline',
  cursor: 'pointer',
});

function EmailLogin() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isSignUp, setIsSignUp] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isSignUp) {
                await signUpWithEmail(email, password);
            } else {
                await signInWithEmail(email, password);
            }
            navigate('/main');
        } catch (error) {
            console.error('Error with email authentication:', error);
            // Handle errors (show message to user)
        }
    };

    return (
        <PageWrapper>
            <StyledContainer>
                <Logo src={logo} alt="MindLabs Logo" />
                <ContentWrapper>
                    <Typography 
                        variant="h1" 
                        gutterBottom 
                        sx={{ 
                            textAlign: 'center', 
                            mt: '100px',
                            mb: '20px'
                        }}
                    >
                        Decode those DMs 💬🔍
                    </Typography>
                    <StyledPaper>
                        <Typography component="h2" variant="h2" sx={{ mb: 2 }}>
                            {isSignUp ? 'Sign Up' : 'Sign In'}
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                {isSignUp ? 'Sign Up' : 'Sign In'}
                            </Button>
                            <ToggleText 
                                variant="body2"
                                onClick={() => setIsSignUp(!isSignUp)}
                                sx={{ textAlign: 'center' }}
                            >
                                {isSignUp ? 'Already have an account? Sign In' : "Don't have an account? Sign Up"}
                            </ToggleText>
                        </Box>
                    </StyledPaper>
                </ContentWrapper>
            </StyledContainer>
            <StyledFooter>
                <Footer />
            </StyledFooter>
        </PageWrapper>
    );
}

export default EmailLogin;