import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { logPageView, logEvent } from '../services/analytics';
import { 
  Typography, Container, TextField, 
  Paper, Input, List, ListItem, ListItemText, CircularProgress, Box, Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteIcon from '@mui/icons-material/Delete';
import { analyzeConversation } from '../services/chatgpt';
import { useNavigate } from 'react-router-dom';
import { createWorker } from 'tesseract.js';
import { getResultsList, auth } from '../services/firebase';
import Header from './Header';
import Footer from './Footer';
import convoIcon from '../images/convo.png';

const StyledContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    paddingTop: theme.spacing(15), // Mobile padding
    '& > *:not(.highlight-box)': {
      marginBottom: theme.spacing(2.5),
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(10), // Desktop padding
    },
  }));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
    borderRadius: '20px',
    '& fieldset': {
      borderColor: '#BDA7A0',
    },
    '& textarea': {
      padding: '30px',
    },
  },
  '& .MuiOutlinedInput-input': {
    borderRadius: '20px',
  },
  filter: 'drop-shadow(0px 0px 20px rgba(0, 0, 0, 0))',
  width: '100%',
  marginTop: theme.spacing(2.5),
  marginBottom: theme.spacing(2.5),
}));

const DropzoneArea = styled(Paper)(({ theme }) => ({
    height: 160,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: `2px dashed white`,
    backgroundColor: 'transparent',  // Changed to transparent
    color: 'white',
    borderRadius: '20px',
    boxShadow: 'none',
    width: '100%',
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(0),
  }));

const ResultsContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(10), // 80px gap
}));

const ResultBox = styled(Box)(({ theme }) => ({
  border: '1px solid #BF80BD',
  borderRadius: '20px',
  padding: theme.spacing(2),
  width: '100%',
  marginBottom: theme.spacing(2),
}));

function MainPage() {
  const [text, setText] = useState('');
  const [files, setFiles] = useState([]);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [previousResults, setPreviousResults] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname);
    const startTime = Date.now();
    return () => {
      const timeSpent = Math.round((Date.now() - startTime) / 1000);
      logEvent('Page', 'Time Spent', 'MainPage', timeSpent);
    };
  }, [location]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsLoggedIn(!!user);
      if (user) {
        fetchPreviousResults(user.uid);
      } else {
        navigate('/');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const fetchPreviousResults = async (userId) => {
    try {
      const results = await getResultsList(userId);
      setPreviousResults(results);
    } catch (error) {
      console.error('Error fetching previous results:', error);
    }
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleFileDrop = useCallback((event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files).filter(file => file.type.startsWith('image/'));
    setFiles(prevFiles => [...prevFiles, ...droppedFiles]);
    logEvent('Action', 'Drop', 'MainPage_DropImage', droppedFiles.length);
  }, []);

  const handleFileInput = (event) => {
    const selectedFiles = Array.from(event.target.files).filter(file => file.type.startsWith('image/'));
    setFiles(prevFiles => [...prevFiles, ...selectedFiles]);
    logEvent('Action', 'Upload', 'MainPage_UploadImage', selectedFiles.length);
  };

  const handleDeleteFile = (index) => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    logEvent('Button', 'Click', 'MainPage_DeleteFile');
  };

  const transcribeImage = async (file) => {
    const worker = await createWorker();
    await worker.load();
    await worker.loadLanguage('eng');
    await worker.initialize('eng');
    const { data: { text } } = await worker.recognize(file);
    await worker.terminate();
    return text;
  };

  const handleAnalyze = async () => {
    logEvent('Button', 'Click', 'MainPage_Analyze');
    setIsAnalyzing(true);
    try {
      const results = await analyzeConversation(text, files);
      navigate('/results', { state: { results } });
    } catch (error) {
      console.error('Error during analysis:', error);
    } finally {
      setIsAnalyzing(false);
    }
  };

  const handleViewPreviousResult = (resultId) => {
    logEvent('Button', 'Click', 'MainPage_ViewPreviousResult');
    navigate(`/full-results/${resultId}`);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <Box component="main" sx={{ flexGrow: 1, pb: 10 }}>
        {isLoggedIn && (
          <StyledContainer maxWidth="sm">
            <Typography variant="h1" gutterBottom>
              Ready to decode those DMs? 💬🔍
            </Typography>
            <Typography variant="h2" gutterBottom sx={{ mt: 2.5 }}>
                Drop your conversation screenshots or copy-paste the texts below.
            </Typography>
            <Input
              type="file"
              id="file-input"
              inputProps={{
                multiple: true,
                accept: "image/*",
              }}
              style={{ display: 'none' }}
              onChange={handleFileInput}
            />
            <label htmlFor="file-input" style={{ width: '100%' }}>
              <DropzoneArea
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleFileDrop}
              >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <img src={convoIcon} alt="Conversation icon" style={{ width: 70, marginBottom: '10px' }} />
                  <Typography style={{ color: 'white' }}>Drag and drop images here or click to upload</Typography>
                </div>
              </DropzoneArea>
            </label>
            {files.length > 0 && (
              <Box sx={{ width: '100%' }}>
                <Typography variant="body2" gutterBottom>
                  Selected file(s):
                </Typography>
                {files.map((file, index) => (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <InsertDriveFileIcon sx={{ mr: 1 }} />
                    <Typography variant="body2" sx={{ flexGrow: 1 }}>
                      {file.name}
                    </Typography>
                    <Button
                      size="small"
                      onClick={() => handleDeleteFile(index)}
                      startIcon={<DeleteIcon />}
                    >
                      Delete
                    </Button>
                  </Box>
                ))}
              </Box>
            )}
            <StyledTextField
              multiline
              rows={6}
              variant="outlined"
              value={text}
              onChange={handleTextChange}
              placeholder="Paste your conversation here"
            />
            <Typography variant="body1" gutterBottom>
              We do not store any user data on our servers. It will be deleted immediately after analysis.🔒
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 2.5 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAnalyze}
                disabled={isAnalyzing}
                sx={{ minWidth: '200px' }}
              >
                {isAnalyzing ? <CircularProgress size={24} /> : 'ANALYZE'}
              </Button>
            </Box>

            <ResultsContainer>
              <Typography variant="h1" gutterBottom sx={{ mt: 10 }}>
                Previous Results
              </Typography>
              {previousResults.length > 0 ? (
                <List sx={{ width: '100%', padding: 0 }}>
                  {previousResults.map((result) => (
                    <ResultBox key={result.id}>
                      <ListItem 
                        button 
                        onClick={() => handleViewPreviousResult(result.id)}
                        sx={{ padding: 0 }}
                      >
                        <ListItemText 
                          primary={new Date(result.createdAt.toDate()).toLocaleString()} 
                          primaryTypographyProps={{ variant: 'body2' }}
                        />
                      </ListItem>
                    </ResultBox>
                  ))}
                </List>
              ) : (
                <Typography variant="body1" sx={{ mt: 2 }}>
                  You don't have any results yet.
                </Typography>
              )}
            </ResultsContainer>
          </StyledContainer>
        )}
      </Box>
      <Footer />
    </Box>
  );
}

export default MainPage;