import React, { useState, useEffect } from 'react';
import { logEvent } from '../services/analytics';
import { Button, Box, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { signInWithGoogle, signInWithApple, auth, signOut } from '../services/firebase';
import logo from '../images/logo.svg';

const HeaderContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(2, 2.5),
    boxSizing: 'border-box',
    background: 'transparent',
    position: 'absolute', // Changed from 'relative' to 'absolute'
    top: 0,
    left: 0,
    zIndex: 10, // Increased to ensure it's above other elements
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 12.5),
    },
  }));
  
  const Logo = styled('img')(({ theme }) => ({
    width: '150px',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100px',
    },
  }));
  
  const ActionButton = styled(Button)(({ theme }) => ({
    fontSize: '16px',
    height: '50px',
    borderRadius: '25px',
    backgroundColor: 'transparent', // Add a background to ensure button is visible
  }));

function Header() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsLoggedIn(!!user);
    });

    return () => unsubscribe();
  }, []);

  const handleLogin = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGoogleLogin = async () => {
    logEvent('Button', 'Click', 'Header_GoogleLogin');
    try {
      await signInWithGoogle();
      navigate('/main');
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
    handleClose();
  };

  const handleAppleLogin = async () => {
    logEvent('Button', 'Click', 'Header_AppleLogin');
    try {
      await signInWithApple();
      navigate('/main');
    } catch (error) {
      console.error('Error signing in with Apple:', error);
    }
    handleClose();
  };

  const handleEmailLogin = () => {
    logEvent('Button', 'Click', 'Header_EmailLogin');
    navigate('/email-login');
    handleClose();
  };

  const handleLogout = async () => {
    logEvent('Button', 'Click', 'Header_Logout');
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <HeaderContainer>
      <Logo src={logo} alt="MindLabs Logo" />
      <ActionButton 
        variant="outlined" 
        onClick={isLoggedIn ? handleLogout : () => navigate('/login')}
      >
        {isLoggedIn ? 'LOG OUT' : 'LOG IN'}
      </ActionButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleGoogleLogin}>Sign in with Google</MenuItem>
        <MenuItem onClick={handleEmailLogin}>Sign in with Email</MenuItem>
      </Menu>
    </HeaderContainer>
  );
}

export default Header;