import React, { useState, useEffect } from 'react';
import { logPageView, logEvent } from '../services/analytics';
import { Button, Typography, Container, Box, CircularProgress, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { initiatePayment } from '../services/stripe';
import { styled } from '@mui/material/styles';
import { saveResults, auth } from '../services/firebase';
import Header from './Header';
import Footer from './Footer';

// Import SVG images
import t1 from '../images/t1.svg';
import t2 from '../images/t2.svg';
import t3 from '../images/t3.svg';

const StyledContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    paddingTop: theme.spacing(15), // Mobile padding
    '& > *:not(.highlight-box)': {
      marginBottom: theme.spacing(2.5),
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(10), // Desktop padding
    },
  }));

  const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: 'white',
    borderRadius: '20px',
    padding: theme.spacing(3.75),
    width: '100%',
    border: '1px solid #BDA7A0',
    position: 'relative',
    maxHeight: '400px',
    overflowY: 'auto',
    textAlign: 'left',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: '150px',
      background: 'linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1))',
      pointerEvents: 'none',
    },
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  }));
  
  const TextContainer = styled(Box)({
    paddingBottom: '100px', // Add padding to ensure text doesn't hide behind the gradient
  });

const HighlightBoxContainer = styled(Box)(({ theme }) => ({
    width: '100%', // This ensures the container takes full width
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch', // This makes sure all children (HighlightBoxes) stretch to full width
}));

const HighlightBox = styled(Box)(({ theme }) => ({
    width: '100%', // This ensures the box takes full width
    backgroundColor: '#FAC5FF',
    border: '1px solid #BF80BD',
    borderRadius: '20px',
    padding: '20px',
    marginBottom: theme.spacing(1),
}));

function ResultsPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const [previewText, setPreviewText] = useState('');
    const [fullResults, setFullResults] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [tensionImage, setTensionImage] = useState('');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        logPageView(location.pathname);
        const startTime = Date.now();
        return () => {
            const timeSpent = Math.round((Date.now() - startTime) / 1000);
            logEvent('Page', 'Time Spent', 'ResultsPage', timeSpent);
        };
    }, [location]);

    useEffect(() => {
        if (location.state?.results) {
            const results = location.state.results;
            setFullResults(results);
            setPreviewText(results.slice(0, 300) + '...'); // Show first 200 characters as preview
            logEvent('Action', 'Load', 'ResultsPage_LoadResults');
        }
        // Randomly select a tension image
        const images = [t1, t2, t3];
        const selectedImage = images[Math.floor(Math.random() * images.length)];
        setTensionImage(selectedImage);
        logEvent('Action', 'Load', 'ResultsPage_LoadTensionImage', { image: selectedImage });
    }, [location.state?.results]);

    const handleFullReport = async () => {
        logEvent('Button', 'Click', 'ResultsPage_UnlockFullReport');
        setIsProcessing(true);
        try {
            if (!auth.currentUser) {
                console.error('User not authenticated');
                navigate('/login', { state: { from: location.pathname } });
                return;
            }

            const resultId = await saveResults(auth.currentUser.uid, fullResults);
            if (!resultId) {
                throw new Error('Failed to save results');
            }

            await initiatePayment(resultId);
            logEvent('Action', 'Success', 'ResultsPage_InitiatePayment');
        } catch (error) {
            console.error('Error in handleFullReport:', error);
            alert(error.message || 'An error occurred. Please try again.');
            logEvent('Action', 'Error', 'ResultsPage_UnlockFullReport', { error: error.message });
            setIsProcessing(false);
        }
    };

    const handleGoBack = () => {
        logEvent('Button', 'Click', 'ResultsPage_GoBack');
        navigate('/main');
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', }}>
            <Header />
            <Box component="main" sx={{ flexGrow: 1, pb: 10 }}>
                <StyledContainer maxWidth="sm">
                    <Typography variant="h1">
                        OMG 😱 We've Uncovered Some Juicy Details!
                    </Typography>
                    <Typography variant="h2">
                        Here's a sneak peek of what we've found:
                    </Typography>
                    <StyledBox>
                        <Typography variant="body3" style={{ whiteSpace: 'pre-line', textAlign: 'left' }}>
                            {previewText}
                        </Typography>
                    </StyledBox>
                    {/*}
          <Typography variant="h2">
            Tension level
          </Typography>
          <Box display="flex" justifyContent="center" width="100%">
            <Box 
              component="img" 
              src={tensionImage} 
              alt="Tension level" 
              sx={{ 
                width: '400px', 
                maxWidth: '100%', 
                height: 'auto',
                aspectRatio: 'auto',
                objectFit: 'contain'
              }} 
            />
          </Box>
          */}
                    <Typography variant="h2" sx={{ mt: 5 }}>
                        But Wait, There's More! 👀
                    </Typography>
                    <Typography variant="body2">
                        Unlock the full report to:
                    </Typography>
                    <HighlightBoxContainer>
                        <HighlightBox><Typography variant="body1" align="left">Get the COMPLETE breakdown of your convo</Typography></HighlightBox>
                        <HighlightBox><Typography variant="body1" align="left">Spot hidden red flags 🚩 and green flags 💚</Typography></HighlightBox>
                        <HighlightBox><Typography variant="body1" align="left">Understand his true feelings and intentions</Typography></HighlightBox>
                        <HighlightBox><Typography variant="body1" align="left">Receive personalized advice to level up your chat game</Typography></HighlightBox>
                    </HighlightBoxContainer>
                    <Typography variant="h2" sx={{ mt: 5 }}>
                        PLUS: Become a Text Detective! 🕵️‍♀️
                    </Typography>
                    <Typography variant="body2">
                        With your full report, you can:
                    </Typography>
                    <HighlightBoxContainer>
                        <HighlightBox><Typography variant="body1" align="left">Ask our AI anything about your convo</Typography></HighlightBox>
                        <HighlightBox><Typography variant="body1" align="left">Get answers to your burning questions</Typography></HighlightBox>
                        <HighlightBox><Typography variant="body1" align="left">Dive deeper into specific messages or patterns</Typography></HighlightBox>
                    </HighlightBoxContainer>
                    <Typography variant="body1" sx={{ mt: 5 }}>
                        All this insider info for just $3.99!<br />
                        That's less than your daily iced latte, but way more revealing! ☕️
                    </Typography>
                    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} justifyContent="space-between" width="100%">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleFullReport}
                            disabled={isProcessing}
                            fullWidth={isMobile}
                            sx={{ mb: isMobile ? 2 : 0 }}
                        >
                            {isProcessing ? <CircularProgress size={24} /> : 'Unlock for $3.99'}
                        </Button>
                        <Button variant="outlined" onClick={handleGoBack} fullWidth={isMobile}>
                            Go Back
                        </Button>
                    </Box>
                </StyledContainer>
            </Box>
            <Footer />
        </Box>
    );
}

export default ResultsPage;