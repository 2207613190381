import React from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithGoogle } from '../services/firebase';
import { logEvent } from '../services/analytics';
import { Button, Typography, Box, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import logo from '../images/logo.svg';
import Footer from './Footer';

const PageWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
});

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: 1,
  padding: theme.spacing(2.5, 2.5),
}));

const Logo = styled('img')({
  width: '150px',
  height: 'auto',
  marginTop: '20px',
});

const LoginBox = styled(Box)({
  border: '1px solid #BDA7A0',
  borderRadius: '20px',
  maxWidth: '450px',
  width: '100%',
  padding: '35px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const LoginButton = styled(Button)({
  width: '100%',
  height: '60px',
  borderRadius: '30px',
  textTransform: 'none',
  fontSize: '16px',
});

const GoogleButton = styled(LoginButton)({
  backgroundColor: 'white',
  color: 'black',
  border: '1px solid #ccc',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
});

const EmailButton = styled(LoginButton)({
  backgroundColor: '#FF8DB8',
  color: 'black',
  '&:hover': {
    backgroundColor: '#C2E9A0',
  },
});

const ContentWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: 1,
  width: '100%',
});

const StyledFooter = styled(Box)({
  width: '100%',
});

function LoginPage() {
    const navigate = useNavigate();
  
    const handleGoogleLogin = async () => {
      logEvent('Button', 'Click', 'LoginPage_GoogleLogin');
      try {
        await signInWithGoogle();
        navigate('/main');
      } catch (error) {
        console.error('Error signing in with Google:', error);
      }
    };
  
    const handleEmailLogin = () => {
      logEvent('Button', 'Click', 'LoginPage_EmailLogin');
      navigate('/email-login');
    };
  
    return (
      <PageWrapper>
        <StyledContainer>
          <Logo src={logo} alt="MindLabs Logo" />
          <ContentWrapper>
            <Typography 
              variant="h1" 
              gutterBottom 
              sx={{ 
                textAlign: 'center', 
                mt: '100px',
                mb: '20px'
              }}
            >
              Decode those DMs 💬🔍
            </Typography>
            <LoginBox>
              <GoogleButton
                variant="outlined"
                startIcon={<img src="https://console.anthropic.com/images/google.svg" alt="Google logo" />}
                onClick={handleGoogleLogin}
              >
                Continue with Google
              </GoogleButton>
              <Box my={1.25}>
                <Typography variant="body1">OR</Typography>
              </Box>
              <EmailButton
                variant="contained"
                onClick={handleEmailLogin}
              >
                Continue with email
              </EmailButton>
            </LoginBox>
          </ContentWrapper>
        </StyledContainer>
        <StyledFooter>
          <Footer />
        </StyledFooter>
      </PageWrapper>
    );
  }
  
  export default LoginPage;