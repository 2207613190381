import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { logPageView, logEvent } from '../services/analytics';
import { Button, Typography, Container, Box, useTheme, useMediaQuery, Card, CardContent, Avatar, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { signInWithGoogle, signInWithApple } from '../services/firebase';
import Header from './Header';
import Footer from './Footer';
import headImg from '../images/head-img.png';
import avatar1 from '../images/i1.png';
import avatar2 from '../images/i2.png';
import avatar3 from '../images/i3.png';

const PageContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    paddingTop: theme.spacing(10), // Add padding to account for absolutely positioned header
  }));
  
  const BlurredCircleContainer = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    overflow: 'hidden',
    height: '100%',
    zIndex: -1,
  }));
  
  const BlurredCircle = styled(Box)(({ theme, isMobile }) => ({
    position: 'absolute',
    top: '0px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: isMobile ? '600px' : '800px',
    height: isMobile ? '600px' : '800px',
    borderRadius: '50%',
    background: 'rgba(175, 221, 250, 0.7)',
    filter: 'blur(100px)',
    WebkitFilter: 'blur(100px)',
    zIndex: 0,
  }));
  

// Styled components
const StyledContainer = styled(Container)(({ theme }) => ({
    maxWidth: '1080px !important',
    width: '100%',
    padding: theme.spacing(0, 2.5),
    position: 'relative', // Add this line
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 2.5),
    },
  }));

const MainContent = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'center',
    width: '100%',
    maxWidth: '1080px',
    margin: '0 auto',
    textAlign: 'center',
    gap: '30px',
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'left',
    },
}));

const TextContent = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    width: '100%',
    [theme.breakpoints.up('md')]: {
        width: '50%',
    },
}));

const HeadImage = styled('img')(({ theme }) => ({
    width: '100%',
    height: 'auto',
    [theme.breakpoints.down('md')]: {
        width: '60vw',
    },
    [theme.breakpoints.up('md')]: {
        width: '45%',
        marginLeft: theme.spacing(4),
    },
}));

const CenteredSection = styled(Box)(({ theme }) => ({
    width: '100%',
    maxWidth: '1080px',
    margin: '0 auto',
    marginTop: theme.spacing(12.5), // 100px gap
    marginBottom: theme.spacing(12.5), // 100px gap
    textAlign: 'center',
    '& > *:not(:last-child)': {
        marginBottom: '30px',
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
    },
}));

const Section = styled(Box)(({ theme }) => ({
    width: '100%',
    maxWidth: '1080px',
    margin: '0 auto',
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    '& > *:not(:last-child)': {
        marginBottom: '30px',
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
    },
}));

const TestimonialsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '30px',
    [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
    },
}));

const TestimonialBox = styled(Box)(({ theme }) => ({
    width: 'calc(32% - 20px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    boxShadow: 'none',
    [theme.breakpoints.down('md')]: {
        width: '100%',
    },
}));

const TestimonialContent = styled(Box)(({ theme }) => ({
    marginBottom: '16px', // Add this line
    [theme.breakpoints.down('md')]: {
        marginBottom: '10px',
    },
}));

const AvatarNameBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
});

const StyledAvatar = styled(Avatar)({
    width: '30px',
    height: '30px',
    marginRight: '8px',
});

const FeatureContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: '20px',
    marginBottom: '30px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  }));
  
  const FeatureCard = styled(Card)(({ theme }) => ({
    background: 'white',
    border: '1px solid #BF80BD',
    borderRadius: '20px',
    flex: '1 1 0',
    minHeight: '100px',
    padding: '30px',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      minHeight: '180px',
    },
  }));
  
  const FeatureHeadline = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
  }));

const HowToContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    alignItems: 'center',
    marginBottom: '30px',
}));

const HowToBox = styled(Box)(({ theme }) => ({
    border: '1px solid #BF80BD',
    borderRadius: '20px',
    padding: '20px',
    width: '100%',
    maxWidth: '600px',
}));


function LandingPage() {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState(null);

    const features = [
        {
          headline: "Discover Hidden Gems in Your Relationship 💎",
          body: "Unearth surprising truths and insights that could blow your mind."
        },
        {
          headline: "Decode Their True Intentions 🕵️‍♀️",
          body: "Understand better what they're REALLY saying in every message."
        },
        {
          headline: "Spot the Signs: Green Lights or Red Flags? 🚩",
          body: "Quickly identify the signals that matter most in your relationship."
        }
      ];    

    useEffect(() => {
        logPageView(location.pathname);
        const startTime = Date.now();
        return () => {
            const timeSpent = Math.round((Date.now() - startTime) / 1000);
            logEvent('Page', 'Time Spent', 'LandingPage', timeSpent);
        };
    }, [location]);

    const handleLoginClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleGoogleLogin = async () => {
        logEvent('Button', 'Click', 'LandingPage_GoogleLogin');
        try {
            await signInWithGoogle();
            navigate('/main');
        } catch (error) {
            console.error('Error signing in with Google:', error);
        }
        handleClose();
    };

    const handleAppleLogin = async () => {
        logEvent('Button', 'Click', 'LandingPage_AppleLogin');
        try {
            await signInWithApple();
            navigate('/main');
        } catch (error) {
            console.error('Error signing in with Apple:', error);
        }
        handleClose();
    };

    const handleEmailLogin = () => {
        logEvent('Button', 'Click', 'LandingPage_EmailLogin');
        navigate('/email-login');
        handleClose();
    };

    const TryNowButton = ({ section }) => (
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => navigate('/login')}
          sx={{
            padding: '0 30px',
            alignSelf: isMobile ? 'center' : 'flex-start',
            marginTop: theme.spacing(2),
          }}
        >
          TRY NOW
        </Button>
      );

    return (
        <PageContainer>
            <Header />
            <BlurredCircleContainer>
                    <BlurredCircle isMobile={isMobile} />
                </BlurredCircleContainer>
            <StyledContainer>
                {/* Hero Section */}
                
                <MainContent>
                    <TextContent>
                        <Typography variant="h1">
                            Reveal the Hidden Truth in Your DMs.
                        </Typography>
                        <Typography variant="body2">
                            Tired of misunderstandings and mixed signals? Our AI digs deep into your chats, uncovering the hidden layers of your relationship🕵️‍♂️💬
                        </Typography>
                        <TryNowButton section="HeroSection" />
                    </TextContent>
                    <HeadImage src={headImg} alt="Relationship Analysis" />
                </MainContent>

                {/* Testimonials Section */}
                {/* Testimonials Section */}
                <CenteredSection>
                    <Typography variant="h2">Why People Love It</Typography>
                    <TestimonialsContainer>
                        {[
                            { name: "Sarah", age: 24, text: "OMG, this app totally saved me from a situationship!", avatar: avatar1 },
                            { name: "Jess", age: 28, text: "It picked up on all the little compliments and gestures I missed. Now I know he is definitely into me!", avatar: avatar2 },
                            { name: "Mia", age: 22, text: "It said my ex is abusive 😭", avatar: avatar3 }
                        ].map((testimonial, index) => (
                            <TestimonialBox key={index}>
                                <TestimonialContent>
                                    <Typography variant="h3" gutterBottom>"{testimonial.text}"</Typography>
                                </TestimonialContent>
                                <AvatarNameBox>
                                    <StyledAvatar src={testimonial.avatar} alt={testimonial.name} />
                                    <Typography variant="h4" sx={{ fontFamily: 'Inter', fontWeight: 500, fontSize: '18px' }}>
                                        {testimonial.name}, {testimonial.age}
                                    </Typography>
                                </AvatarNameBox>
                            </TestimonialBox>
                        ))}
                    </TestimonialsContainer>
                </CenteredSection>

                {/* Features Section */}
                <CenteredSection>
                    <Typography variant="h2">Why You Need This App</Typography>
                    <FeatureContainer>
                    {features.map((feature, index) => (
                        <FeatureCard key={index}>
                        <FeatureHeadline variant="h4">{feature.headline}</FeatureHeadline>
                        <Typography variant="body1">{feature.body}</Typography>
                        </FeatureCard>
                    ))}
                    </FeatureContainer>
                    <TryNowButton section="FeaturesSection" />
                </CenteredSection>

                {/* How It Works Section */}
                <CenteredSection>
                    <Typography variant="h2">How It Works</Typography>
                    <HowToContainer>
                        {[
                            "① Drop those DM texts or screenshots 📸",
                            "② Let our AI do its magic ✨",
                            "③ Get a juicy breakdown of the sitch and personalized tips 🍵",
                        ].map((step, index) => (
                            <HowToBox key={index}>
                                <Typography variant="body2">
                                    {step}
                                </Typography>
                            </HowToBox>
                        ))}
                    </HowToContainer>
                    <Typography variant="body1">
                        Ready to decode your DMs? Upload your first convo now!
                    </Typography>
                    <TryNowButton section="HowItWorksSection" />
                </CenteredSection>

                {/* Extra Info Section 
                <CenteredSection>
                    <Typography variant="h2">Extra Sauce:</Typography>
                    <FeatureContainer>
                        {[
                            '"Your secrets are safe with us" lockdown privacy deets 🔒',
                            '"No shady business" – use with your boo\'s OK 👍',
                            '"We\'re not fortune tellers" – keeping it real about what AI can do 🔮',
                            '"Talk it out, don\'t just text about it" – we\'re all about that face-to-face tea ☕️'
                        ].map((info, index) => (
                            <FeatureCard key={index}>
                                <Typography variant="body2">
                                    {info}
                                </Typography>
                            </FeatureCard>
                        ))}
                    </FeatureContainer>
                    <Typography variant="body1">
                        Remember, queens: This app is a tool, not a crystal ball. Always trust your gut and keep it real with your boo! 👑💖
                    </Typography>
                    <TryNowButton section="ExtraInfoSection" />
                </CenteredSection>*/}
            </StyledContainer>
            <Footer />
        </PageContainer>
    );
}

export default LandingPage;