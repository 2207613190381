import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { logPageView, logEvent } from '../services/analytics';
import { Button, Typography, Container, TextField, Box, useMediaQuery } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import { askQuestion } from '../services/chatgpt';
import { getFullResults, updateQuestions } from '../services/firebase';
import Header from './Header';
import Footer from './Footer';

const StyledContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    paddingTop: theme.spacing(15), // Mobile padding
    '& > *:not(.highlight-box)': {
      marginBottom: theme.spacing(2.5),
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(10), // Desktop padding
    },
  }));

  const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: 'white',
    borderRadius: '20px',
    padding: theme.spacing(3.75),
    width: '100%',
    border: '1px solid #BDA7A0',
    overflowY: 'auto', // Changed from 'auto' to allow scrolling if needed
    textAlign: 'left',
    // Removed maxHeight and ::after pseudo-element (gradient overlay)
  }));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
    borderRadius: '20px',
    '& fieldset': {
      borderColor: '#BDA7A0',
    },
    '& textarea': {
      padding: '30px',
    },
  },
  '& .MuiOutlinedInput-input': {
    borderRadius: '20px',
  },
  filter: 'drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.3))',
  width: '100%',
  marginTop: theme.spacing(2.5),
  marginBottom: theme.spacing(2.5),
}));

function FullResultsPage() {
  const [question, setQuestion] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const navigate = useNavigate();
  const [results, setResults] = useState("No results available.");
  const { resultId } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname);
    const startTime = Date.now();
    return () => {
      const timeSpent = Math.round((Date.now() - startTime) / 1000);
      logEvent('Page', 'Time Spent', 'FullResultsPage', timeSpent);
    };
  }, [location]);

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const data = await getFullResults(resultId);
        setResults(data.results);
        setChatHistory(data.questions || []);
        logEvent('Action', 'Load', 'FullResultsPage_LoadResults');
      } catch (error) {
        console.error('Error fetching results:', error);
        logEvent('Action', 'Error', 'FullResultsPage_LoadResults', { error: error.message });
      }
    };

    fetchResults();
  }, [resultId]);

  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleAskQuestion = async () => {
    if (chatHistory.length < 20 && question.trim() !== '') {
      logEvent('Button', 'Click', 'FullResultsPage_AskQuestion');
      try {
        const fullContext = results + '\n\n' + chatHistory.map(chat => `Q: ${chat.question}\nA: ${chat.answer}`).join('\n\n');
        const answer = await askQuestion(question, fullContext);
        
        const newChatHistory = [{ question, answer }, ...chatHistory];
        setChatHistory(newChatHistory);
        await updateQuestions(resultId, newChatHistory);
        setQuestion('');
        logEvent('Action', 'Success', 'FullResultsPage_QuestionAnswered');
      } catch (error) {
        console.error('Error asking question:', error);
        logEvent('Action', 'Error', 'FullResultsPage_AskQuestion', { error: error.message });
      }
    }
  };

  const handleGoBack = () => {
    logEvent('Button', 'Click', 'FullResultsPage_GoBackToMain');
    navigate('/main');
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <Box component="main" sx={{ flexGrow: 1, pb: 10 }}>
        <StyledContainer maxWidth="sm">
          <Typography variant="h1" gutterBottom>
            OMG 😱 We've Uncovered Some Juicy Details!
          </Typography>
          <StyledBox>
            <Typography variant="body1" style={{ whiteSpace: 'pre-wrap', textAlign: 'left' }}>
              {results}
            </Typography>
          </StyledBox>
          <Typography variant="h2" gutterBottom>
            Use our Text Detective! 🕵️‍♀️ tool. Ask anything.
          </Typography>
          <StyledTextField
            fullWidth
            variant="outlined"
            value={question}
            onChange={handleQuestionChange}
            placeholder="Ask a question about the analysis"
          />
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleAskQuestion} 
            disabled={chatHistory.length >= 20 || question.trim() === ''}
            fullWidth={isMobile}
            sx={{ mb: 2.5 }}
          >
            Ask
          </Button>
          {chatHistory.length > 0 && (
            <StyledBox>
              {chatHistory.map((chat, index) => (
                <Box key={index} mb={2}>
                  <Typography variant="subtitle1" fontWeight="bold">Q: {chat.question}</Typography>
                  <Typography variant="body1">A: {chat.answer}</Typography>
                </Box>
              ))}
            </StyledBox>
          )}
          <Box display="flex" justifyContent="center" width="100%">
            <Button variant="outlined" onClick={handleGoBack} fullWidth={isMobile}>
              Go Back to Main
            </Button>
          </Box>
        </StyledContainer>
      </Box>
      <Footer />
    </Box>
  );
}

export default FullResultsPage;