// Footer.js
import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import logo from '../images/logo.svg';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';

const FooterContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  background: '#000000',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '60px 0',
  marginTop: 'auto', // This will push the footer to the bottom
}));

const InvertedLogo = styled('img')({
  filter: 'invert(1)',
  maxWidth: '200px',
  marginBottom: '10px',
});

const SocialIconsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px',
});

function Footer() {
  return (
    <FooterContainer>
      <InvertedLogo src={logo} alt="Logo" />
      <SocialIconsContainer>
        <IconButton
          href="https://www.instagram.com/mindreader.ai/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Instagram"
        >
          <InstagramIcon style={{ color: 'white' }} />
        </IconButton>
        <IconButton
          href="https://www.facebook.com/profile.php?id=61564104411407"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Facebook"
        >
          <FacebookIcon style={{ color: 'white' }} />
        </IconButton>
      </SocialIconsContainer>
      <Typography variant="body2" style={{ color: '#aaa', fontSize: '14px', marginTop: '20px', }}>
        MindReader AI © 2024, Los Angeles, California
      </Typography>
    </FooterContainer>
  );
}

export default Footer;