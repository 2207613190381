import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    background: {
      //default: '#FCF5EB',
      default: '#FFAAFC',
    },
    primary: {
      main: '#E097B2',
    },
    text: {
      primary: '#000000',
    },
  },
  typography: {
    fontFamily: 'Inter, Arial, sans-serif',
    h1: {
      fontFamily: '"Souvenir Medium", serif',
      fontSize: '46px',
      fontWeight: 500,
      color: '#000000',
      lineHeight: '48px',
    },
    h2: {
      fontFamily: '"Souvenir Medium", serif',
      fontSize: '26px',
      fontWeight: 500,
      color: '#000000',
    },
    body1: {
      fontSize: '16px',
      color: '#000000',
      letterSpacing: '-0.03em',
      lineHeight: '22px',
    },
    body2: {
        fontFamily: 'Inter', 
        fontWeight: 500, 
        fontSize: '20px',
        lineHeight: '26px',
    },
    body3: {
        fontFamily: 'Inter', 
        fontWeight: 500, 
        fontSize: '22px',
        lineHeight: '26px',
    },
    h3: {
      fontFamily: '"Glida Display", serif',
      fontSize: '23px',
      fontWeight: 400,
      color: '#000000',
    },
    h4: {
        fontFamily: '"Souvenir Medium", serif',
        fontSize: '22px',
        lineHeight: '26px',
        fontWeight: 500,
        color: '#000000',
    },
  },
  components: {
    MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
          },
        },
      },
      MuiButton: {    
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontFamily: 'Inter, Arial, sans-serif',
            fontSize: '24px',
            fontWeight: 700,
            letterSpacing: '-0.03em',
            height: '60px',
            borderRadius: '30px',
            border: '1px solid #000000',
            padding: '0 30px',
            color: '#000000',
            boxShadow: 'none',
          },
          containedPrimary: {
            backgroundColor: '#FF8DB8',
            '&:hover': {
              backgroundColor: '#C2E9A0',
              boxShadow: 'none',
            },
          },
          outlined: {
            fontSize: '16px',
            height: '50px',
            borderRadius: '25px',
          },
        },
      },
  },
});

// Apply responsive styles after theme creation
theme.typography.h1 = {
  ...theme.typography.h1,
  [theme.breakpoints.down('md')]: {
    fontSize: '38px',
    lineHeight: '40px',
  },
};

export default theme;