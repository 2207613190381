import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  signInWithPopup, 
  GoogleAuthProvider, 
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  OAuthProvider,
  signOut as firebaseSignOut 
} from 'firebase/auth';
import { getFirestore, collection, addDoc, updateDoc, doc, getDoc, getDocs, query, where, orderBy } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyC4ZQsYCBT2f8ILzlsGT2S2MQeoRuwWudU",
    authDomain: "mind-teller.firebaseapp.com",
    projectId: "mind-teller",
    storageBucket: "mind-teller.appspot.com",
    messagingSenderId: "120540094450",
    appId: "1:120540094450:web:cded342fb40274b16ed56b",
    measurementId: "G-LTCPBKCD9P"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export const signInWithGoogle = () => {
  const provider = new GoogleAuthProvider();
  return signInWithPopup(auth, provider);
};

export const signInWithApple = () => {
  const provider = new OAuthProvider('apple.com');
  return signInWithPopup(auth, provider);
};

export const signInWithEmail = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const signUpWithEmail = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

export const signOut = () => {
  return firebaseSignOut(auth);
};

export const saveResults = async (userId, results) => {
  try {
    const docRef = await addDoc(collection(db, 'results'), {
      userId,
      results,
      createdAt: new Date(),
      questions: []
    });
    return docRef.id;
  } catch (error) {
    console.error('Error saving results:', error);
    throw error;
  }
};

export const getResultsList = async (userId) => {
  const q = query(
    collection(db, 'results'),
    where('userId', '==', userId),
    orderBy('createdAt', 'desc')
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

export const getFullResults = async (resultId) => {
  const docRef = doc(db, 'results', resultId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    throw new Error('No such document!');
  }
};

export const updateQuestions = async (resultId, newQuestions) => {
  const docRef = doc(db, 'results', resultId);
  await updateDoc(docRef, {
    questions: newQuestions
  });
};

export { auth, db };