// sk-proj-bdg3KkAPTLDOzIdhvqXHT3BlbkFJG7v6Q6fydwMPH1Mf8y9T

import axios from 'axios';

const API_URL = 'https://api.openai.com/v1/chat/completions';
const API_KEY = 'sk-proj-bdg3KkAPTLDOzIdhvqXHT3BlbkFJG7v6Q6fydwMPH1Mf8y9T';

const convertImageToBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = error => reject(error);
  });
};

export const analyzeConversation = async (conversation, imageFiles) => {
  try {
    const messages = [
      { role: 'system', content: 'You are a relationship analyst.' },
      {
        role: 'user',
        content: [
          { type: 'text', text: `Analyze this conversation between me and my text partner and provide insights: ${conversation}. In the first paragraph, tell what the partner thinks about me, include both positive insights and areas to watch out for and call out the name of the partner. Do not use a header before the first paragraph. Make it intriguing, tease me, so I would want to know more. In the following paragraphs, try to extract as many insights into their relationship dynamycs, spot red and green flags, analyze what kind of person they are. Make a conclusion. Make it as detailed as possible. Tone-of-voice: use a friendly conversational tone but keep it professional to make it look creadible.` }
        ]
      }
    ];

    // Add image content if available
    if (imageFiles && imageFiles.length > 0) {
      for (const file of imageFiles) {
        const base64Image = await convertImageToBase64(file);
        messages[1].content.push({
          type: 'image_url',
          image_url: {
            url: `data:image/jpeg;base64,${base64Image}`,
            detail: 'auto'  // You can change this to 'low' or 'high' if needed
          }
        });
      }
    }

    const response = await axios.post(
      API_URL,
      {
        model: 'gpt-4o-mini',  // Using GPT-4o mini as requested
        messages: messages,
        max_tokens: 300,
      },
      {
        headers: {
          'Authorization': `Bearer ${API_KEY}`,
          'Content-Type': 'application/json',
        },
      }
    );

    const result = response.data.choices[0].message.content;
    console.log('ChatGPT Analysis Result:', result);
    return result;
  } catch (error) {
    console.error('Error in analyzeConversation:', error);
    throw error;
  }
};

export const askQuestion = async (question, context) => {
    try {
      const response = await axios.post(
        API_URL,
        {
          model: 'gpt-4o-mini',
          messages: [
            { role: 'system', content: 'You are a relationship analyst.' },
            { role: 'user', content: `Context: ${context}\n\nQuestion: ${question}` },
          ],
          max_tokens: 300,
        },
        {
          headers: {
            'Authorization': `Bearer ${API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      const result = response.data.choices[0].message.content;
      console.log('ChatGPT Question Answer:', result);
      return result;
    } catch (error) {
      console.error('Error in askQuestion:', error);
      throw error;
    }
  };