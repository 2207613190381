// App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box } from '@mui/material';
import theme from './theme';
import './fonts.css';
import LandingPage from './components/LandingPage';
import EmailLogin from './components/EmailLogin';
import LoginPage from './components/LoginPage';
import MainPage from './components/MainPage';
import ResultsPage from './components/ResultsPage';
import FullResultsPage from './components/FullResultsPage';
import { initGA } from './services/analytics';

function App() {
  useEffect(() => {
    initGA('G-LTCPBKCD9P'); // Replace with your Measurement ID
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/email-login" element={<EmailLogin />} />
          <Route path="/main" element={<MainPage />} />
          <Route path="/results" element={<ResultsPage />} />
          <Route path="/full-results/:resultId" element={<FullResultsPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;